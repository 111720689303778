<template>
    <section class="ncci">
        <Nav
            class="mt-5 mb-4"
            route-name="StrategicProjects"
            parent-page-name="Стратегические проекты"
            current-page-name="НЦКИ"
        />

        <div class="ncci__main-img d-none d-xl-flex mb-sm-0">
            <div class="research-center__blurred-img mt-3 mt-lg-auto ms-lg-auto d-flex align-items-lg-end">
                <h2 class="research-center__title mt-lg-auto ms-lg-5 mb-lg-5">
                    {{ getNcciPageInfo('title') }}
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="research-center__logo ms-auto mb-5 me-5 d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="research-center__title research-center__title--dark">{{ getNcciPageInfo('title') }}</h2>
        </div>

        <div class="ncci-cards d-flex flex-column flex-lg-row mt-5">
            <div class="ncci-card d-flex flex-column col-12 col-lg-8 col-xl-6 pb-4">
                <div class="ncci-card__img-wrapper">
                    <img
                        class="ncci-card__img"
                        src="../../assets/views/ncci/card-img.svg"
                        alt=""
                    >
                </div>

                <div class="ncci-card__text mt-4">
                    {{ getNcciPageInfo('image_text_1') }}
                </div>
            </div>

            <div class="ncci__rzhd col-12 col-lg-4 col-xl-6 ms-auto">
                <div class="d-flex rzhd-card p-5">
                    <img class="d-block w-50" src="../../assets/views/ncci/rzhd.svg" alt="">

                    <div class="rzhd-card__text w-50 ms-4">{{ getNcciPageInfo('subtitle_1') }}</div>
                </div>

                <div class="ncci-quote-card d-flex flex-column p-3 p-sm-4 p-lg-5 mt-4">
                    <div class="ncci-quote-card__text">
                        {{ getNCCIQuote('text') }}
                    </div>

                    <div class="ms-auto mt-4">
                        <div class="ncci-quote-card__author">{{ getNCCIQuote('author_name') }}</div>
                        <div class="ncci-quote-card__position">{{ getNCCIQuote('author_post') }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column flex-lg-row align-items-center research-center__task mt-5 py-5">
            <div class="col-12 col-lg-8 col-xl-6 d-flex justify-content-center justify-content-lg-start">
                <div class="task-img-card">
                    <img
                        class="task__img"
                        src="../../assets/views/ncci/ncci-task.png"
                        alt=""
                    >
                </div>
            </div>

            <div
                class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <h3 class="task__title">{{ getNcciPageInfo('subtitle_2') }}</h3>
                <div class="task__text mt-3">
                    {{ getNcciPageInfo('subtext_2') }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import {computed} from "vue";
import { useStore } from "vuex";

export default {
    name: "NCCI",

    components: {
        Nav
    },

    setup() {
        const store = useStore()
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getNcciPage')
        const ncciPage = computed(() => store.getters['pages/ncciPage'])

        const getNcciPageInfo = (key) => {
            if (ncciPage.value) {
                return ncciPage.value[key]
            }

            return ''
        }

        const getNCCIQuote = (key) => {
            if (ncciPage.value) {
                return ncciPage.value.quotes[0][key]
            }

            return ''
        }

        return {
            getNcciPageInfo,
            getNCCIQuote
        }
    }
}
</script>
